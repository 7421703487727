<template>
  <label class="checkbox" :class="checkboxClass">
    <input type="checkbox" :checked="checked" @change="onChangeCheckbox" />
  </label>
</template>

<script lang="ts">
import type { PropType } from "vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";

export default defineNuxtComponent({
  components: { BaseIcon },
  props: {
    checked: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ["change"],

  setup(props, { emit }) {
    function onChangeCheckbox() {
      emit("change");
    }

    const checkboxClass = computed(() => ({
      checkbox_checked: props.checked,
      checkbox_disabled: props.disabled,
    }));

    return {
      onChangeCheckbox,
      checkboxClass,
    };
  },
});
</script>
